import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import Section from "../components/section";
import Page from "../components/page";
import FullWidthImage from "../components/FullWidthImage";
import { Title, SubTitle } from "../components/text";

export const ContactPosting = ({
  content,
  contentComponent,
  description,
  title,
  image,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  // console.warn(image)
  // console.log(content)
  return (
    <Page>
      <FullWidthImage height="50vh" image={image} parallax={50} dim={0.4}>
        <Title bright>{title}</Title>
        <SubTitle bright>{description}</SubTitle>
      </FullWidthImage>
      <Section>
        {helmet || ""}
        <PostContent className="content" content={content} />
      </Section>
    </Page>
  );
};

export default (props) => {
  const { markdownRemark: post } = props.data;

  return (
    <ContactPosting
      content={post.html}
      contentComponent={HTMLContent}
      description={post.frontmatter.description}
      helmet={<Helmet title={`Wesmans | Career | ${post.frontmatter.title}`} />}
      title={post.frontmatter.title}
      image={post.frontmatter.image}
    />
  );
};

export const pageQuery = graphql`
  query ContactPostingByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`;
